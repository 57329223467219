/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-19 17:31:53
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-24 20:49:22
 */
import EnumMap from '@/plugins/enumMap'
import CustomAddForm from './CustomAddForm'
export default {
  name: 'CustomAdd',
  components: {
    CustomAddForm
  },
  data () {
    return {
      form: {},
      loading: false
    }
  },
  methods: {
    customStatus (val) {
      const name = new EnumMap('custom_status').mapping(val)
      return name
    },
    updateStatus (val) {
      this.$set(this.form, 'custom_status', val)
      this.loading = false
    }
  },
  beforeMount () {
    if (!/add/.test(this.$route.path)) {
      this.loading = true
    }
  }
}
